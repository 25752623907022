import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/EN/400error',
    name: 'EN_EN_400error',
    component: () => import('../components/EN/400error.vue')
  },
  {
    path: '/EN/403error',
    name: 'EN_403error',
    component: () => import('../components/EN/403error.vue')
  },
  {
    path: '/EN/404error',
    name: 'EN_404error',
    component: () => import('../components/EN/404error.vue')
  },
  {
    path: '/EN/493error',
    name: 'EN_493error',
    component: () => import('../components/EN/493error.vue')
  },
  {
    path: '/EN/540error',
    name: 'EN_540error',
    component: () => import('../components/EN/540error.vue')
  },
  {
    path: '/EN/545error',
    name: 'EN_545error',
    component: () => import('../components/EN/545error.vue')
  },
  {
    path: '/EN/552error',
    name: 'EN_552error',
    component: () => import('../components/EN/552error.vue')
  },
  {
    path: '/EN/TemplatePage',
    name: 'EN_TemplatePage',
    component: () => import('../components/EN/TemplatePage.vue')
  },
  {
    path: '/EN/ErrorMessage',
    name: 'EN_ErrorMessage',
    component: () => import('../components/EN/ErrorMessage.vue')
  },
  {
    path: '/EN/LoginPage',
    name: 'EN_LoginPage',
    component: () => import('../components/EN/LoginPage.vue')
  },
  {
    path: '/EN/ChangePasswordPage',
    name: 'EN_ChangePasswordPage',
    component: () => import('../components/EN/ChangePasswordPage.vue')
  },
  {
    path: '/EN/CannotgetError',
    name: 'EN_CannotgetError',
    component: () => import('../components/EN/CannotgetError.vue')
  },
  {
    path: '/EN/ErrTunnelConnectionFailed',
    name: 'EN_ErrTunnelConnectionFailed',
    component: () => import('../components/EN/ErrTunnelConnectionFailed.vue')
  },
  {
    path: '/EN/InvalidUsernameorPassword',
    name: 'EN_InvalidUsernameorPassword',
    component: () => import('../components/EN/InvalidUsernameorPassword.vue')
  },
  {
    path: '/EN/OTPPage',
    name: 'EN_OTPPage',
    component: () => import('../components/EN/OTPPage.vue')
  },
  {
    path: '/EN/NotAuthorized',
    name: 'EN_NotAuthorized',
    component: () => import('../components/EN/NotAuthorized.vue')
  },
  {
    path: '/EN/LoginPasswordChanged',
    name: 'EN_LoginPasswordChanged',
    component: () => import('../components/EN/LoginPasswordChanged.vue')
  },
  {
    path: '/EN/ChangePasswordhistory',
    name: 'EN_ChangePasswordhistory',
    component: () => import('../components/EN/ChangePasswordhistory.vue')
  },
  {
    path: '/EN/ChangePasswordinvalid',
    name: 'EN_ChangePasswordinvalid',
    component: () => import('../components/EN/ChangePasswordinvalid.vue')
  },
  {
    path: '/EN/ChangePasswordnotmatch',
    name: 'EN_ChangePasswordnotmatch',
    component: () => import('../components/EN/ChangePasswordnotmatch.vue')
  },
  {
    path: '/EN/ChangePasswordsame',
    name: 'EN_ChangePasswordsame',
    component: () => import('../components/EN/ChangePasswordsame.vue')
  },
  {
    path: '/EN/InvalidAuthenticationCode',
    name: 'EN_InvalidAuthenticationCode',
    component: () => import('../components/EN/InvalidAuthenticationCode.vue')
  },
  {
    path: '/EN/otpnotyouremail',
    name: 'EN_otpnotyouremail',
    component: () => import('../components/EN/otpnotyouremail.vue')
  },
  {
    path: '/EN/OTPemailhasnotarrived',
    name: 'EN_OTPemailhasnotarrived',
    component: () => import('../components/EN/OTPemailhasnotarrived.vue')
  },
  {
    path: '/EN/top',
    name: 'EN_top',
    component: () => import('../components/EN/top.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
